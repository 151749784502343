import styled, { css } from 'styled-components';

export const Container = styled.nav`
  margin-top: 10px;
  height: 95px;
  display: inline-flex;
  width: 100%;
  max-width: 410px;
  padding-left: 5px;
  .scroll-container {
    display: flex;
    align-content: center;
    padding-bottom: 15px;
  }
`;
