import React, { useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Switch from 'react-switch';
import { v4 as uuidv4 } from 'uuid';

import { FiSmartphone, FiArrowLeft, FiXCircle, FiX } from 'react-icons/fi';

import Modal from 'react-modal';
import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';

import MenuItemList from './MenuItemList';

import { useCart } from '../../../context/cart';
import { useAuth } from '../../../context/auth';
import { useOrder } from '../../../context/order';

import iconTake from '../../../assets/images/iconTake.svg';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  Footer,
  HeaderAction,
  CartDescription,
  RouterDomLink,
  ItemListHeader,
  MakingOrder,
  Info,
  SwitchUserSms,
  RemoveUser,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  CancelButton,
  MinimumPrice,
  MinimumPriceArea,
  MinimumPriceAreaTitle,
  MinimumPriceAreaPrice,
  MinimumPriceAreaOut,
} from './styles';
import { useBaskets } from '../../../context/BasketsContext';
import { LoadingPage } from 'ui-kit-takeat';
import { toast } from 'react-toastify';
import { formatPrice } from '../../../utils/formatPrice';

const CartDelivery = () => {
  const {
    setUser,
    setUserChosePaySms,
    userPhone,
    userName,
    userLogin,
    restaurantHasSmsService,
    clientPaySms,
    isSmsServiceOptional,
    formatValue,
    restaurantAcceptsDelivery,
    restaurantDeliveryActive,
    restaurantId,
    changeUserInfo,
    setUserDeliveryType,
    setIsScheduling,
    orderSchedulingActive,
    restaurantWithdrawallMinimumPrice,
    restaurantDeliveryMinimumPrice,
    restaurantName,
    tableType,
  } = useAuth();
  const { cart } = useCart();
  const { setOrdersTotalValue } = useOrder();
  const [makingOrder] = useState(false);
  const [userAcceptedSms, setUserAcceptedSms] = useState(clientPaySms);
  const [minimumPricesState, setMinimumPricesState] = useState({
    delivery: restaurantWithdrawallMinimumPrice,
    withdrawal: restaurantDeliveryMinimumPrice,
  });
  const [modalHasOrderIsOpened, setModalHasOrderIsOpened] = useState(false);
  const [userDataState, setUserDataState] = useState('');

  function toggleModalHasOrder() {
    setModalHasOrderIsOpened(!modalHasOrderIsOpened);
  }

  const formRef = useRef(null);
  const { hasOrder } = useBaskets();

  Modal.setAppElement('#root');
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const history = useHistory();

  const totalProductPrice = cart.reduce((TotalAccumulator, order) => {
    console.log('order', order);
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        if (category.more_expensive_only) {
          let totalComplements = 0;
          category.complements.forEach(complement => {
            if (
              totalComplements <
              parseFloat(complement.delivery_price || complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
            ) {
              totalComplements =
                parseFloat(complement.delivery_price || complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0);
            }
          });
          return categoryAccumulator + totalComplements;
        }
        if (category.use_average) {
          const amountAverage = category.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0,
          );

          const totalPriceAverage =
            category.complements
              .map(item => item)
              .reduce((acum, curr) => {
                return acum + (curr.delivery_price || curr.price) * curr.amount;
              }, 0) / amountAverage;

          return totalPriceAverage + categoryAccumulator;
        }
        const totalComplements = category.complements.reduce(
          (complementAccumulator, complement) => {
            return (
              complementAccumulator +
              (complement.delivery_price || complement.price) *
                complement.amount
            );
          },
          0,
        );

        return (
          categoryAccumulator +
          totalComplements +
          (order.is_combo
            ? parseFloat(category.cheapest) * category.minimum
            : 0)
        );
      },
      0,
    );

    if (order.weight) {
      setOrdersTotalValue(
        TotalAccumulator +
          (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
            order.amount,
      );

      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price * order.weight)) *
          order.amount
      );
    }

    setOrdersTotalValue(
      TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount,
    );
    return (
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
    );
  }, 0);

  function handleToggleSms() {
    if (userAcceptedSms.toString() === 'true') {
      setUserAcceptedSms(false);
    } else {
      setUserAcceptedSms(true);
    }
  }

  function userLogout() {
    changeUserInfo();
  }

  const [isModalMinimumErrorOpened, setIsModalMinimumErrorOpened] = useState(
    false,
  );

  function toggleModalMinimumError() {
    setIsModalMinimumErrorOpened(!isModalMinimumErrorOpened);
  }

  function handleMakeOrderAgain() {
    if (!userPhone) {
      if (
        userDataState?.phone?.length < 15 ||
        userDataState?.name?.length <= 0
      ) {
        alert('Favor preencher os dados corretamente.');
      } else if (cart?.length > 0) {
        if (
          Number(restaurantDeliveryMinimumPrice) > 0 &&
          totalProductPrice < Number(restaurantDeliveryMinimumPrice) &&
          Number(restaurantWithdrawallMinimumPrice) > 0 &&
          totalProductPrice < Number(restaurantWithdrawallMinimumPrice)
        ) {
          toggleModalMinimumError();
          toggleModalHasOrder();
        } else {
          setUserChosePaySms(userAcceptedSms);

          setUser(userDataState.phone, userDataState.name);
          userLogin(userDataState.phone, userDataState.name);
          setOrdersTotalValue(Number(totalProductPrice));

          if (orderSchedulingActive) {
            history.push('/app/delivery-type');
          }

          if (
            restaurantAcceptsDelivery.toString() === 'true' &&
            restaurantDeliveryActive.toString() === 'true'
          ) {
            history.push('/app/delivery-type');
          }

          if (
            orderSchedulingActive.toString() === 'false' &&
            restaurantAcceptsDelivery.toString() === 'false' &&
            restaurantDeliveryActive.toString() === 'false'
          ) {
            history.push('/app/payments-delivery');
            setUserDeliveryType('withdrawal');
            setIsScheduling(false);
          }
          if (
            orderSchedulingActive.toString() === 'false' &&
            restaurantAcceptsDelivery.toString() === 'true' &&
            restaurantDeliveryActive.toString() === 'false'
          ) {
            history.push('/app/payments-delivery');
            setUserDeliveryType('withdrawal');
            setIsScheduling(false);
          }
        }
      } else {
        alert(
          'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
        );

        history.goBack();
      }
    } else if (cart.length > 0) {
      const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
      if (!phoneRegex.test(userPhone)) {
        toast.error('O telefone está no formato incorreto.');
        return -1;
      }

      if (
        Number(restaurantDeliveryMinimumPrice) > 0 &&
        totalProductPrice < Number(restaurantDeliveryMinimumPrice) &&
        Number(restaurantWithdrawallMinimumPrice) > 0 &&
        totalProductPrice < Number(restaurantWithdrawallMinimumPrice)
      ) {
        toggleModalMinimumError();
        toggleModalHasOrder();
      } else {
        setUserChosePaySms(userAcceptedSms);

        setOrdersTotalValue(Number(totalProductPrice));

        if (orderSchedulingActive) {
          history.push('/app/delivery-type');
        }

        if (
          restaurantAcceptsDelivery.toString() === 'true' &&
          restaurantDeliveryActive.toString() === 'true'
        ) {
          history.push('/app/delivery-type');
        }

        if (
          orderSchedulingActive.toString() === 'false' &&
          restaurantAcceptsDelivery.toString() === 'false' &&
          restaurantDeliveryActive.toString() === 'false'
        ) {
          history.push('/app/payments-delivery');
          setUserDeliveryType('withdrawal');
          setIsScheduling(false);
        }
        if (
          orderSchedulingActive.toString() === 'false' &&
          restaurantAcceptsDelivery.toString() === 'true' &&
          restaurantDeliveryActive.toString() === 'false'
        ) {
          history.push('/app/payments-delivery');
          setUserDeliveryType('withdrawal');
          setIsScheduling(false);
        }
      }
    } else {
      alert(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
      );

      history.goBack();
    }
  }

  function handleMakeOrder(name, phone) {
    if (hasOrder && hasOrder.length > 0) {
      setModalHasOrderIsOpened(true);
    } else if (!userPhone) {
      if (phone.length < 15 || name.length <= 0) {
        alert('Favor preencher os dados corretamente.');
      } else if (cart.length > 0) {
        if (
          Number(restaurantDeliveryMinimumPrice) > 0 &&
          totalProductPrice < Number(restaurantDeliveryMinimumPrice) &&
          Number(restaurantWithdrawallMinimumPrice) > 0 &&
          totalProductPrice < Number(restaurantWithdrawallMinimumPrice)
        ) {
          toggleModalMinimumError();
          toggleModalHasOrder();
        } else {
          setUserChosePaySms(userAcceptedSms);

          setUser(phone, name);
          userLogin(phone, name);
          setOrdersTotalValue(Number(totalProductPrice));

          if (orderSchedulingActive) {
            history.push('/app/delivery-type');
          }

          if (
            restaurantAcceptsDelivery.toString() === 'true' &&
            restaurantDeliveryActive.toString() === 'true'
          ) {
            history.push('/app/delivery-type');
          }

          if (
            orderSchedulingActive.toString() === 'false' &&
            restaurantAcceptsDelivery.toString() === 'false' &&
            restaurantDeliveryActive.toString() === 'false'
          ) {
            history.push('/app/payments-delivery');
            setUserDeliveryType('withdrawal');
            setIsScheduling(false);
          }
          if (
            orderSchedulingActive.toString() === 'false' &&
            restaurantAcceptsDelivery.toString() === 'true' &&
            restaurantDeliveryActive.toString() === 'false'
          ) {
            history.push('/app/payments-delivery');
            setUserDeliveryType('withdrawal');
            setIsScheduling(false);
          }
        }
      } else {
        alert(
          'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
        );

        history.goBack();
      }
    } else if (cart.length > 0) {
      if (
        Number(restaurantDeliveryMinimumPrice) > 0 &&
        totalProductPrice < Number(restaurantDeliveryMinimumPrice) &&
        Number(restaurantWithdrawallMinimumPrice) > 0 &&
        totalProductPrice < Number(restaurantWithdrawallMinimumPrice)
      ) {
        toggleModalMinimumError();
        toggleModalHasOrder();
      } else {
        setUserChosePaySms(userAcceptedSms);

        setOrdersTotalValue(Number(totalProductPrice));

        if (orderSchedulingActive) {
          history.push('/app/delivery-type');
        }

        if (
          restaurantAcceptsDelivery.toString() === 'true' &&
          restaurantDeliveryActive.toString() === 'true'
        ) {
          history.push('/app/delivery-type');
        }

        if (
          orderSchedulingActive.toString() === 'false' &&
          restaurantAcceptsDelivery.toString() === 'false' &&
          restaurantDeliveryActive.toString() === 'false'
        ) {
          history.push('/app/payments-delivery');
          setUserDeliveryType('withdrawal');
          setIsScheduling(false);
        }

        if (
          orderSchedulingActive.toString() === 'false' &&
          restaurantAcceptsDelivery.toString() === 'true' &&
          restaurantDeliveryActive.toString() === 'false'
        ) {
          history.push('/app/payments-delivery');
          setUserDeliveryType('withdrawal');
          setIsScheduling(false);
        }
      }
    } else {
      alert(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
      );

      history.goBack();
    }
  }

  async function handleSubmit(data) {
    try {
      if (formRef.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Nome obrigatório')
          .matches(
            /^[A-Za-zàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
            'Somente letras',
          )
          .required('Nome obrigatório'),
        phone: Yup.string()
          .matches(
            /^\(\d{2}\) \d{5}-\d{4}$/,
            'O telefone deve estar no formato (99) 99999-9999',
          )
          .required('Telefone obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const nameTrim = data.name.trim();

      if (hasOrder && hasOrder.length > 0) {
        setModalHasOrderIsOpened(true);
        setUserDataState({ name: nameTrim, phone: data.phone });
      } else {
        handleMakeOrder(nameTrim, data.phone);
      }
    } catch (err) {
      const errors = getValidationErrors(err);
      if (formRef.current) {
        formRef.current.setErrors(errors);
      }
      console.log(err);
    }
  }

  return makingOrder ? (
    <LoadingPage duration={3} text="Fazendo seu pedido..." />
  ) : (
    <Container>
      <Content>
        <Modal
          isOpen={isModalMinimumErrorOpened}
          onRequestClose={toggleModalMinimumError}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p style={{ textAlign: 'left' }}>
              Seu pedido está abaixo do valor mínimo. Insira mais itens no
              carrinho para prosseguir.
            </p>

            {restaurantDeliveryMinimumPrice &&
              restaurantDeliveryMinimumPrice > 0 && (
                <MinimumPrice>
                  <span>Valor min delivery: </span>
                  <span style={{ fontWeight: 'bold' }}>
                    {`R$ ${restaurantDeliveryMinimumPrice}`}
                  </span>
                </MinimumPrice>
              )}

            {restaurantWithdrawallMinimumPrice &&
              restaurantWithdrawallMinimumPrice > 0 && (
                <MinimumPrice>
                  <span>Valor min retirada: </span>
                  <span style={{ fontWeight: 'bold' }}>
                    {`R$ ${restaurantWithdrawallMinimumPrice}`}
                  </span>
                </MinimumPrice>
              )}
          </ModalTitle>
          <ModalFooter>
            <CancelButton onClick={toggleModalMinimumError}>
              Cancelar
            </CancelButton>
            <ConfirmButton
              onClick={() => {
                history.push(`/${restaurantName}`);
              }}
            >
              Ok, irei inserir
            </ConfirmButton>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={modalHasOrderIsOpened}
          onRequestClose={toggleModalHasOrder}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p style={{ textAlign: 'center' }}>
              Você já possui pedido em andamento.
            </p>
            <p
              style={{
                fontSize: 14,
                textAlign: 'center',
                fontWeight: 'normal',
              }}
            >
              Tem certeza que deseja fazer outro?
            </p>
          </ModalTitle>
          <ModalFooter>
            <CancelButton onClick={toggleModalHasOrder}>Não</CancelButton>
            <ConfirmButton onClick={handleMakeOrderAgain}>Sim</ConfirmButton>
          </ModalFooter>
        </Modal>
        <HeaderAction>
          <header>
            <h1>Carrinho</h1>
            <RouterDomLink
              onClick={() => {
                history.push(`/${restaurantName}`);
              }}
            >
              <FiArrowLeft color="#c8131b" />{' '}
              <span style={{ color: '#c8131b' }}>Voltar</span>
            </RouterDomLink>
          </header>
        </HeaderAction>
        {userPhone ? (
          <>
            <CartDescription>
              <div>
                <FiSmartphone />
                <p>{userPhone}</p>
              </div>
              <p>{userName}</p>
              <RemoveUser onClick={userLogout}>
                <FiX />
                <p>Limpar meus dados</p>
              </RemoveUser>
            </CartDescription>

            {(minimumPricesState?.delivery > 0 ||
              minimumPricesState?.withdrawal > 0) && (
              <MinimumPriceAreaOut>
                <MinimumPriceArea>
                  <MinimumPriceAreaTitle>$ Pedido mínimo</MinimumPriceAreaTitle>
                  <MinimumPriceAreaPrice>
                    {minimumPricesState?.delivery > 0 &&
                      `Delivery: ${formatPrice(minimumPricesState?.delivery)}`}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {minimumPricesState?.withdrawal > 0 &&
                      `Retirada: ${formatPrice(
                        minimumPricesState?.withdrawal,
                      )}`}
                  </MinimumPriceAreaPrice>
                </MinimumPriceArea>
              </MinimumPriceAreaOut>
            )}

            <Footer>
              <div>
                <small>Valor Total</small>
                <p>{formatPrice(Number(totalProductPrice))}</p>
              </div>

              <button type="button" onClick={handleMakeOrder}>
                Confirmar
              </button>
            </Footer>
          </>
        ) : (
          <>
            <CartDescription>
              <strong>Informações</strong>

              <p>
                Insira suas informações abaixo para prosseguir com seu pedido.
              </p>
            </CartDescription>
            <Info>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="name" placeholder="Nome" />
                <InputMask mask="(99) 99999-9999" maskChar="">
                  <Input name="phone" placeholder="Celular" type="text" />
                </InputMask>

                <Footer>
                  <div>
                    <small>Valor Total</small>
                    <p>{formatPrice(Number(totalProductPrice))}</p>
                  </div>

                  <button type="submit">Confirmar</button>
                </Footer>
              </Form>
            </Info>
          </>
        )}

        <ItemListHeader>
          <div>
            <p>Item</p>
          </div>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {cart.map(item => (
              <MenuItemList item={item} key={uuidv4()} />
            ))}
          </MenuItem>
        </MenuContent>
      </Content>
    </Container>
  );
};

export default CartDelivery;
