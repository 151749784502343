import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'uuidv4';
import { FiArrowLeft } from 'react-icons/fi';

import { useCart } from '../../../context/cart';
import { useOrder } from '../../../context/order';
import { useAuth } from '../../../context/auth';

import api from '../../../services/api';
import axios from 'axios';

import {
  Container,
  Header,
  Content,
  Footer,
  Orders,
  OrdersDetails,
  OrderPrice,
  OrderComplement,
  OrderProduct,
  PaymentMethod,
  PaymentMethodArea,
  CouponArea,
  InputCoupon,
  CouponSelect,
  Coupon,
  CouponStatus,
  OrderObservations,
  OrderImage,
  Order,
  CouponDiscount,
  TotalPrice,
  RouterDomLink,
  CouponInfo,
  AddressArea,
  PaymentMethodType,
  ConfirmAddress,
  LinkToPush,
  Hyperlink,
  TotalDiv,
} from './styles';

import { Button, DefaultInput, LoadingPage } from 'ui-kit-takeat';
import { useBaskets } from '../../../context/BasketsContext';
import { set, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
const ConfirmOrderDelivery = () => {
  const { ordersTotalValue } = useOrder();
  const { addCartToOrder, cart, closeCart, cartPixel } = useCart();
  const { hasOrder } = useBaskets();
  const [birthday, setBirthday] = useState(null);
  const [birthdayCheck, setBirthdayCheck] = useState(null);
  const [birthdayIsLoading, setBirthdayIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState(null);

  const hasRescue = hasOrder.some(
    o =>
      o.rescues?.status === 'pending' ||
      o.rescues?.createdAt < subDays(new Date(), 1),
  );

  const couponInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderValue, setOrderValue] = useState(ordersTotalValue || '0 ');
  const [coupons, setCoupons] = useState([]);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponStatus, setCouponsStatus] = useState('');
  const [activatePhrase, setActivatePhrase] = useState('');
  const {
    userPaymentMethod,
    restaurantId,
    setRestaurantCouponCode,
    isDeliveryByDistance,
    formatValue,
    userChosePaySms,
    clientPaySms,
    restaurantHasSmsService,
    userDeliveryType,
    userAddress,
    userAddressId,
    userChange,
    restaurantDeliveryTax,
    restaurantDeliveryTime,
    restaurantDeliveryTimeAddress,
    userPhone,
    userName,
    tableType,
    tokenClube,
    hasClube,
    keyClube,
    clube,
    isScheduling,
    restaurantPixelId2,
    restaurantMetaToken2,
    subscriptionIdPixel2,
  } = useAuth();

  const history = useHistory();
  const [clientCashback, setClientCashback] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [rescue, setRescue] = useState(0);
  const [totalOrderValue, setTotalOrderValue] = useState(
    userDeliveryType === 'delivery'
      ? Number(orderValue) + Number(restaurantDeliveryTax)
      : Number(orderValue),
  );

  function handleGoPayments() {
    history.push('/app/payments-delivery');
  }

  function getCouponCode(e) {
    setCouponCode(e.target.id);
  }

  const getClientCashback = async () => {
    const res = await axios.get(
      `https://backend.clubecliente.com/public/clients/${userPhone.replace(
        /(-|\s)/gi,
        '',
      )}/${keyClube}`,
    );

    if (res.data.clientBelongsToStore) {
      setClientCashback(res.data.totalClientCashback);
      setIsClient(true);
    }
  };

  const checkBirthday = async () => {
    setBirthdayIsLoading(true);
    const check = await axios.post(
      'https://backend.clubecliente.com/takeat/confirm-birthday',
      {
        phone: userPhone.replace(/(-|\s)/gi, ''),
        birthday,
        token: tokenClube,
      },
    );

    if (check.data.success) {
      setBirthdayCheck(true);
    } else {
      setBirthdayCheck(false);
      toast.error('Data de nascimento incorreta');
    }
    setBirthdayIsLoading(false);
  };

  useEffect(() => {
    if (birthday?.length >= 10) {
      checkBirthday();
    }
  }, [birthday]);

  //pixel_fb
  const aleatorID = uuid();
  const handleSendDataToPixel = useCallback(
    (items, ids) => {
      ReactPixel.fbq(
        'track',
        'InitiateCheckout',
        {
          content_ids: ids,
          currency: 'BRL',
          contents: items,
          value: orderValue,
        },
        {
          eventID: aleatorID,
        },
      );
    },
    [orderValue],
  );

  const handleSendDataToApiConversion = useCallback(
    (items, ids) => {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'InitiateCheckout',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                content_ids: ids,
                currency: 'BRL',
                contents: items,
                value: orderValue,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    },
    [
      orderValue,
      restaurantPixelId2,
      restaurantMetaToken2,
      subscriptionIdPixel2,
    ],
  );

  useEffect(() => {
    const items = cart.map(item => {
      return JSON.stringify({
        id: `${item.name}`,
        quantity: `${item.amount}`,
      });
    });
    const ids = cart.map(item => item.name);

    if (restaurantPixelId2) {
      handleSendDataToPixel(items, ids);
    }

    if (restaurantPixelId2 && restaurantMetaToken2) {
      handleSendDataToApiConversion(items, ids);
    }
  }, [restaurantPixelId2, handleSendDataToPixel, restaurantMetaToken2]);

  function handleChangeAddress() {
    if (isDeliveryByDistance) {
      history.push('/app/search-address-by-distance-delivery');
    } else {
      history.push('/app/search-address-delivery');
    }
  }

  const handleMakeOrder = useCallback(
    async (payment_method, payment_token) => {
      setIsLoading(true);

      await addCartToOrder(
        userPhone,
        userName,
        payment_method,
        'delivery',
        null,
        closeCart,
        payment_token,
        rescue,
      );

      setIsLoading(false);
    },

    [addCartToOrder, userName, userPhone, closeCart, rescue],
  );

  function handleGoToInputCard() {
    history.push('/app/inputcard');
  }

  async function validateCoupon() {
    try {
      let validateCouponBody = {
        order: [...cart],
        restaurant_id: restaurantId,
        coupon_code: couponCode,
        is_withdrawal: userPaymentMethod.type === 'withdrawal',
      };

      if (userAddressId) {
        validateCouponBody.buyer_address_id = userAddressId;
      }
      const response = await api.post(
        '/public/discount-coupons/validate',
        validateCouponBody,
      );

      const { data } = response;

      if (data.coupon.discount_type === 'free-shipping') {
        // Obs.: Isso evita que o backend faça revalidações nos endreços
        data.total_price -= restaurantDeliveryTax;
        data.discount = restaurantDeliveryTax;
      }

      if(validateCouponBody.is_withdrawal){
        setTotalOrderValue(
          Math.max(
            parseFloat(response.data.total_price) -
              (rescue || 0),
            0,
          ),
        );
      }

      else {
        setTotalOrderValue(
          Math.max(
            parseFloat(response.data.total_price) -
              (rescue || 0) +
              parseFloat(restaurantDeliveryTax),
            0,
          ),
        );
      }

      setCouponDiscount(data.discount);
      setCouponsStatus('activated');

      setRestaurantCouponCode(couponCode.toUpperCase());

      setActivatePhrase('Cupom ativado com sucesso!');
    } catch (err) {
      if (!err.response) {
        console.log('Unknown error: ', err);
      } else if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'coupon_wrong_restaurant':
            setActivatePhrase('Este cupom não pertence a este restaurante.');
            break;
          case 'coupon_amount_ended':
            setActivatePhrase('Este cupom não está mais disponível.');
            break;
          case 'coupon_not_active':
            setActivatePhrase('Este cupom não está ativo');
            break;
          case 'coupon_not_found':
            setActivatePhrase('Cupom não encontrado');
            break;
          case 'coupon_date_expired':
            setActivatePhrase('Este cupom expirou');
            break;
          case 'coupon_minimum_price':
            setActivatePhrase(
              'O valor da compra está abaixo do valor mínimo pro cupom',
            );
            break;
          case 'coupon_distance_limit':
            setActivatePhrase(
              'Você está muito distante para a aplicação deste cupom',
            );
            break;
          case 'coupon_withdrawal_invalid':
            setActivatePhrase('Esse cupom só é valido em pedidos com entrega');
            break;
          default:
            break;
        }
      }

      setCouponsStatus('error');
      setRestaurantCouponCode('');
    }
  }

  useEffect(() => {
    async function getCoupons() {
      try {
        const couponsRestaurant = await api.get(
          `/public/discount-coupons/restaurant/${restaurantId}`,
        );

        setCoupons(couponsRestaurant.data);
      } catch (err) {
        console.log(err);
      }
    }

    getCoupons();
  }, [restaurantId]);

  useEffect(() => {
    if (hasClube && keyClube) {
      getClientCashback();
    }
  }, []);

  useEffect(() => {
    setTotalOrderValue(
      Math.max(
        parseFloat(orderValue) +
          (userDeliveryType === 'delivery'
            ? parseFloat(restaurantDeliveryTax)
            : 0) -
          (rescue || 0) -
          parseFloat(couponDiscount),
        0,
      ),
    );
  }, [rescue, orderValue, restaurantDeliveryTax, couponDiscount]);

  return isLoading ? (
    <LoadingPage duration={3} text="Redirecionando para o pagamento" />
  ) : (
    <Container>
      <Content>
        <Header>
          <RouterDomLink to="/app/payments-delivery">
            <FiArrowLeft />
          </RouterDomLink>
          <h1>Confirmar compra</h1>
        </Header>

        <Orders>
          <h3>Pedidos</h3>

          {cart.map(item => (
            <Order key={uuid()}>
              {item.image && (
                <OrderImage>
                  <img
                    src={item.image.url_thumb || item.image.url}
                    alt={item.name}
                  />
                </OrderImage>
              )}

              <OrdersDetails>
                <OrderProduct>
                  <p>{`${item.amount}x - `}</p>
                  <p>{item.name}</p>
                </OrderProduct>
                <OrderComplement>
                  {item.complement_categories.map(category =>
                    category.complements.map(items => (
                      <p key={uuid()}>{items.name}</p>
                    )),
                  )}
                </OrderComplement>
                <OrderObservations>{item.observation}</OrderObservations>
                <OrderPrice>
                  {formatValue(
                    item.complement_categories.reduce(
                      (categoryAccumulator, category) => {
                        if (!category.additional) {
                          return 0;
                        }

                        if (category.more_expensive_only) {
                          let totalComplements = 0;
                          category.complements.forEach(complement => {
                            if (
                              totalComplements <
                              parseFloat(complement.price) *
                                (parseInt(complement.amount) >= 1 ? 1 : 0)
                            ) {
                              totalComplements =
                                parseFloat(complement.price) *
                                (parseInt(complement.amount) >= 1 ? 1 : 0);
                            }
                          });
                          return categoryAccumulator + totalComplements;
                        }
                        if (category.use_average) {
                          const amountAverage = category.complements.reduce(
                            (accum, curr) => accum + curr.amount,
                            0,
                          );

                          const totalPriceAverage =
                            category.complements
                              .map(item => item)
                              .reduce(
                                (acum, curr) =>
                                  acum +
                                  (curr.delivery_price || curr.price) *
                                    curr.amount,
                                0,
                              ) / amountAverage;

                          return totalPriceAverage + categoryAccumulator;
                        }

                        const totalComplements = category.complements.reduce(
                          (complementAccumulator, complement) => {
                            return (
                              complementAccumulator +
                              complement.price * complement.amount
                            );
                          },
                          0,
                        );

                        return (
                          categoryAccumulator +
                          totalComplements +
                          (item.is_combo
                            ? parseFloat(category.cheapest) * category.minimum
                            : 0)
                        );
                      },
                      0,
                    ) + Number(item.delivery_price || item.price),
                  )}
                </OrderPrice>
              </OrdersDetails>
            </Order>
          ))}
        </Orders>

        <PaymentMethodArea>
          <h3>Método de pagamento</h3>
          <PaymentMethod>
            {userPaymentMethod.name.toString() === 'pix_online' && (
              <PaymentMethodType>
                <p>Pagar online</p>
                <p>{userPaymentMethod.name === 'pix_online' && 'Pix'}</p>
              </PaymentMethodType>
            )}
            {userPaymentMethod.name.toString() === 'picpay' && (
              <PaymentMethodType>
                <p>Pagar online</p>
                <p>{userPaymentMethod.name}</p>
              </PaymentMethodType>
            )}
            {userPaymentMethod.type.toString() === 'online' &&
              userPaymentMethod.name.toString() === 'credito' && (
                <PaymentMethodType>
                  <p>Pagar online</p>
                  <p>
                    {userPaymentMethod.name === 'credito'
                      ? 'Cartão de Crédito'
                      : userPaymentMethod.name}
                  </p>
                </PaymentMethodType>
              )}
            {userPaymentMethod.type.toString() === 'delivery' && (
              <PaymentMethodType>
                <p>Pagar na entrega</p>
                <p>{userPaymentMethod.name}</p>
                {userPaymentMethod.keyword.toString() === 'dinheiro' && (
                  <p>
                    {userChange > 0
                      ? `Troco para R$ ${userChange}`
                      : 'Não quero troco'}
                  </p>
                )}
              </PaymentMethodType>
            )}
            {userPaymentMethod.type.toString() === 'withdrawal' && (
              <PaymentMethodType>
                <p>Pagar na retirada</p>
                <p>{userPaymentMethod.name}</p>
              </PaymentMethodType>
            )}
            <button type="button" onClick={handleGoPayments}>
              Alterar
            </button>
          </PaymentMethod>
        </PaymentMethodArea>

        {userDeliveryType.toString() === 'delivery' && (
          <AddressArea>
            <h3>Endereço de entrega</h3>
            <ConfirmAddress>
              <div>
                <p>{`${userAddress.street}, ${userAddress.number} - ${userAddress.neighborhood}`}</p>
                <p>{userAddress.complement || ''}</p>
                <p>{userAddress.reference || ''}</p>
                <p>{`${userAddress.city}, ${userAddress.state}`}</p>
                <p>
                  {restaurantDeliveryTax &&
                    `Taxa de entrega: ${formatValue(restaurantDeliveryTax)}`}
                </p>

                {!isScheduling &&
                  (restaurantDeliveryTimeAddress > 0 ? (
                    <p>
                      Tempo de entrega: Aprox.
                      {restaurantDeliveryTimeAddress} min.
                    </p>
                  ) : (
                    restaurantDeliveryTime > 0 && (
                      <p>
                        Tempo de entrega: Aprox.
                        {restaurantDeliveryTime} min.
                      </p>
                    )
                  ))}
              </div>
              <button type="button" onClick={handleChangeAddress}>
                Alterar
              </button>
            </ConfirmAddress>
          </AddressArea>
        )}

        <CouponArea>
          {tokenClube && isClient && (
            <>
              <h3>
                Resgate de cashback -{' '}
                {hasRescue
                  ? 'Você já possui um resgate em andamento'
                  : `Você possui: R$${clientCashback}`}
              </h3>
                  
              {!hasRescue && parseFloat(clientCashback || 0) > 0 &&
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    alignItems: 'flex-end',
                  }}
                >
                  <div>
                    <span>Data de nascimento</span>
                    <DefaultInput
                      placeholder="__/__/____"
                      mask="99/99/9999"
                      width="155px"
                      style={{ height: 40, padding: '5px 10px' }}
                      value={birthday || ''}
                      onChange={e => setBirthday(e.target.value)}
                    />
                  </div>
                  <Button
                    title={
                      rescue > 0 ? 'Resgate adicionado' : 'Resgatar Cashback'
                    }
                    isLoading={birthdayIsLoading}
                    disabled={
                      rescue > 0 ||
                      parseFloat(rescue) > orderValue ||
                      !isClient ||
                      hasRescue ||
                      !birthdayCheck
                    }
                    onClick={() => {
                      if (
                        clube?.settings.minimo &&
                        clientCashback < parseFloat(clube?.settings.minimo)
                      ) {
                        toast.error(
                          `Resgate abaixo do mínimo permitido (R$${parseFloat(
                            clube?.settings.minimo,
                          )})`,
                        );
                      } else if (rescue <= 0 && !hasRescue) {
                        setRescue(clientCashback);
                      }
                    }}
                  />
                </div>
              }

              <h3>Adicionar cupom</h3>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 10,
            }}
          >
            <DefaultInput
              placeholder="Cole ou digite o código aqui"
              style={{ height: 40, padding: '5px 10px' }}
              value={couponCode || ''}
              onChange={e => setCouponCode(e.target.value)}
            />
            <Button title="Validar" type="button" onClick={validateCoupon} />
          </div>

          {activatePhrase && (
            <CouponStatus status={couponStatus}>{activatePhrase}</CouponStatus>
          )}

          {coupons.length > 0 && (
            <p style={{ margin: 0 }}>
              ... ou selecione abaixo 1 cupom disponível
            </p>
          )}

          <CouponSelect>
            {coupons &&
              coupons.length > 0 &&
              coupons.map(item => (
                <Coupon
                  id={item.code}
                  onClick={e => {
                    getCouponCode(e);
                  }}
                  key={uuid()}
                >
                  {item.name}
                  {item.minimum_price > 0 && (
                    <CouponInfo
                      id={item.code}
                      onClick={e => {
                        getCouponCode(e);
                      }}
                    >
                      {`Min. de produtos: ${formatValue(item.minimum_price)}`}
                    </CouponInfo>
                  )}

                  {item.maximum_discount && (
                    <CouponInfo
                      id={item.code}
                      onClick={e => {
                        getCouponCode(e);
                      }}
                    >
                      {`Desconto max.: ${formatValue(item.maximum_discount)}`}
                    </CouponInfo>
                  )}

                  {item?.distance_limit && item.distance_limit > 0 && (
                    <CouponInfo
                      id={item.code}
                      onClick={e => {
                        getCouponCode(e);
                      }}
                    >
                      {`Distância max. (km): ${Number(item?.distance_limit)}`}
                    </CouponInfo>
                  )}
                </Coupon>
              ))}
          </CouponSelect>
        </CouponArea>
      </Content>

      <Footer>
        {restaurantHasSmsService.toString() === 'true' &&
        clientPaySms.toString() === 'true' &&
        userChosePaySms.toString() === 'true' ? (
          <div>
            <small>Valor Total + SMS</small>
            {couponDiscount && (
              <CouponDiscount>
                Cupom: {formatValue(couponDiscount)}
              </CouponDiscount>
            )}
            {userDeliveryType === 'delivery' ? (
              <TotalPrice>
                {formatValue(
                  Number(orderValue) + Number(restaurantDeliveryTax) + 0.1,
                )}
              </TotalPrice>
            ) : (
              <TotalPrice>{formatValue(Number(orderValue) + 0.1)}</TotalPrice>
            )}
          </div>
        ) : (
          <TotalDiv>
            <div style={{ minWidth: '70px' }}>
              <small>Valor Total</small>
              {couponDiscount > 0 && (
                <CouponDiscount>{formatValue(couponDiscount)}</CouponDiscount>
              )}
              {rescue > 0 && (
                <CouponDiscount>{formatValue(rescue)}</CouponDiscount>
              )}
            </div>
            <div>
              <TotalPrice>{formatValue(Number(totalOrderValue))}</TotalPrice>
            </div>
          </TotalDiv>
        )}

        {userPaymentMethod.name.toString() === 'picpay' && (
          <button
            style={{ backgroundColor: '#c8131b' }}
            type="submit"
            onClick={() => {
              handleMakeOrder('picpay');
            }}
          >
            Fazer Pedido
          </button>
        )}
        {userPaymentMethod.name.toString() === 'pix_online' && (
          <button
            style={{ backgroundColor: '#c8131b' }}
            type="submit"
            onClick={() => {
              handleMakeOrder('pix_online');
            }}
          >
            Fazer Pedido
          </button>
        )}
        {userPaymentMethod.type.toString() === 'delivery' && (
          <button
            style={{ backgroundColor: '#c8131b' }}
            type="submit"
            onClick={() => {
              handleMakeOrder('delivery');
            }}
          >
            Fazer Pedido
          </button>
        )}
        {userPaymentMethod.type.toString() === 'withdrawal' && (
          <button
            style={{ backgroundColor: '#c8131b' }}
            type="submit"
            onClick={() => {
              handleMakeOrder('withdrawal');
            }}
          >
            Fazer Pedido
          </button>
        )}

        {userPaymentMethod.name.toString() === 'credito' && (
          <LinkToPush
            to={{
              pathname: '/app/inputcard',
              state: { userPhone, userName, rescue },
            }}
          >
            <div style={{ width: '100%' }}>
              <button
                style={{ width: '100%', backgroundColor: '#c8131b' }}
                type="submit"
              >
                Pagar
              </button>
            </div>
          </LinkToPush>
        )}
      </Footer>
    </Container>
  );
};

export default ConfirmOrderDelivery;
